import { Box, BoxProps, Flex, LightMode, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import * as React from 'react'

interface PricingCardProps extends BoxProps {
  button: React.ReactElement
  name: string
  description: string
  price: number
  amountSaved?: number
  duration: 'monthly' | 'yearly'
  benefits: string[]
}

export default function PricingCard(props: PricingCardProps) {
  const { button, name, description, price, duration, benefits, ...rest } = props;

  return (
    <Flex
      direction="column"
      px="6"
      py="8"
      rounded="lg"
      pos="relative"
      maxW="2xl"
      mx="auto"
      {...rest}
    >
      <Box flex="1">
        <Text fontSize="2xl" lineHeight="1" fontWeight="bold">
          {name}
        </Text>
        <Flex align="center" fontWeight="extrabold" mt="4" mb="3">
          <Text fontSize={{ base: '6xl', xl: '7xl' }} lineHeight="1" flexShrink={0}>
            ${price}
          </Text>
          <Text fontSize="xl" lineHeight="1.2">
            <br /> {duration === 'monthly' ? '/month' : '/year'}
          </Text>
        </Flex>
        <Box mt="6">
          <Text fontSize="xl" fontWeight="semibold" mb="6">
            {description}
          </Text>
          <UnorderedList spacing="3">
            {benefits && benefits.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Box>
      <Box mt="10">
        <LightMode>{button}</LightMode>
      </Box>
    </Flex>
  )
}